<template>
  <div class="container p-0 m-0">
    <div class="row full-heigth p-0 m-0">

      <loading :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage">
      </loading>

      <div class="col-12">
        <button type="button" class="royalc-btn-close pt-2 pr-0 float-right" @click="onClose()"
          aria-label="Close modal"><i class="fas fa-times"></i></button>
        <h5 class="box-title mb-1 pb-1">Selecione um hiperlink</h5>

        <hr class="my-2">

        <!-- Filtros -->
        <div class="row">

        </div>

        <!-- Lista de seleção -->
        <div class="row mt-2">
          <!-- Table -->
          <custom-vue-table
            ref="EmailListTable"
            http-method="get"
            :api-url="listEndpoint"
            :fields="fieldsTable"
            :sort-order="sortOrder"
            :append-params="activeFilters"
            @row-clicked="onRowClicked"
            track-by="id">
            <template slot="actions" slot-scope="props">
              <div class="custom-actions">
                <a class="btn btn-link"
                    v-on:click="onSelect(props.rowData)">
                  <i class="text-primary ti-target font-20"></i>
                </a>
              </div>
            </template>
          </custom-vue-table>
          <!-- END Table -->
        </div>

        <hr class="my-2">

        <!-- Botões abaixo -->
        <div class="row">
          <div class="col-12 align-center pb-2">
              <button class="btn btn-sm btn-link btn-a-color m-1" @click="onClose()">
                <span >Cancelar</span>
              </button>

              <!-- <button type="button" class="btn btn-sm btn-outline-light mt-1 pull-right" @click="onSelect()">
                Selecionar
              </button> -->

              <button type="button" class="btn btn-sm btn-link mt-1 pull-right" @click="onShow">
                Console
              </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import CustomVueTable from '@/components/Table/CustomVueTable'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// import DashboardService from '@/services/DashboardService'

export default {
  name: 'HiperlinkSelectModal',
  components: {
    Loading,
    CustomVueTable
  },
  props: {
    selecteds: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    selected: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      isLoading: false,
      fullPage: true,
      multiSort: true,
      listEndpoint: process.env.VUE_APP_APIENDPOINT + 'v3/hiperlink',
      activeFilterList: {
      },
      filter: {},
      sortOrder: [
        { field: 'idHiperlink', direction: 'asc' }
      ],
      select: null
    }
  },
  beforeMount () {
    this.getOptions()
  },
  mounted () {
  },
  methods: {
    onShow () {
    },
    onLoading () {
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
      this.$jQuery('table.table').reflowTable()

      if (this.$route.query.process) {
        if (this.$refs.vuetable.$data.tableData) {
          if (this.$refs.vuetable.$data.tableData.length > 0) {
            this.onRowClicked({ data: this.$refs.vuetable.$data.tableData[0] })
          }
        }
      }
    },
    onfocus () {
    },
    getOptions () {
    },
    formatStatus (value) {
      if (!value && value !== 0) {
        return ' - '
      }

      let statusList = [
        {
          'label': this.$i18n.t('dashboard.inativo'),
          'color': '#F3614E'
        },
        {
          'label': this.$i18n.t('dashboard.ativo'),
          'color': '#5ec65f'
        }
      ]

      let index = 0
      if (value) {
        index = 1
      }

      let status = statusList[index]

      return '<span style="color: ' + status.color + '">' + status.label + '</span>'
    },
    formatBoolean (value) {
      if (!value) {
        return '<span style="color: #F3614E">Não</span>'
      }

      return '<span style="color: #5ec65f">Sim</span>'
    },
    formatCarouselLink (values) {
      if (!this.$util.isset(values) || values.length <= 0) {
        return '<span style="color: #F3614E">Não</span>'
      }

      let links = values.map(x => {
        return '<a href="/dashboard/admin/carousel/' + x.carrossel.id_carrossel + '">' + x.carrossel.titulo + '</a>'
      })

      return links.join(' <br/> ')
    },
    formatImage (value) {
      if (!value && value !== 0) {
        return ' - '
      }

      return '<img src="' + value + '" width="100" height="100">'
    },
    onRowClicked (row) {
      this.onSelect(row)
    },
    onClose () {
      this.$emit('close')
    },
    onSelect (record) {
      this.$emit('before-close', record.data)
      this.onClose()
    },
    isset (value) {
      return value !== null && value !== undefined && value !== ''
    }
  },
  computed: {
    activeFilters () {
      return this.filter
    },
    fieldsTable () {
      return [
        {
          name: 'hiperlink.id_hiperlink',
          title: this.$i18n.t('dashboard.id'),
          sortField: 'idHiperlink',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'hiperlink.image_url',
          title: this.$i18n.t('dashboard.image'),
          sortField: 'image_url',
          formatter: (value) => this.formatImage(value)
        },
        {
          name: 'hiperlink.titulo',
          title: this.$i18n.t('dashboard.titulo'),
          sortField: 'titulo',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'dashboard',
          title: this.$i18n.t('dashboard.tela-inicial'),
          sortField: 'dashboard',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.formatBoolean(value)
        },
        {
          name: 'hiperlink.carrosseis',
          title: this.$i18n.t('dashboard.carousel.title'),
          sortField: 'carrosseis',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.formatCarouselLink(value)
        },
        {
          name: 'hiperlink.ativo',
          title: this.$i18n.t('dashboard.status'),
          sortField: 'ativo',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.formatStatus(value)
        },
        {
          name: 'actions',
          title: 'Selecionar',
          dataClass: 'text-center wrap-actions',
          titleClass: 'text-center'
        }
      ]
    }
  }
}
</script>

<style scoped>
.close-button {
  position: absolute;
  top: 15px;
  right: 33px;
}

tr.internal {
  background-color: #fcf8e3;
}

.royalc-btn-close {
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: #84754E;
  background: transparent;
}

.btn-a-color {
  color: #534B41 !important;
}
</style>
