<template>
  <div>

    <loading :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage">
    </loading>

    <form method="post" @submit.prevent="onSubmit('frmCarousel')" data-vv-scope="frmCarousel" novalidate="novalidate">
      <div class="row justify-content-md-center">
        <div class="col-12 col-md-12">
          <div class="card card-fullheight">
            <div class="card-body">
              <h5 class="box-title mb-3 mt-2">{{ $t('dashboard.carousel.new-edit') }}</h5>

              <hr>

              <!--  -->
              <div class="row custom-row">

                <!--  Título  -->
                <div class="col-lg-3 col-md-6 form-group px-2">
                  <label class="small grey-label">Título</label>
                  <input v-model="carousel.titulo" type="text" class="md-form-control">
                </div>

                <!-- Ativo -->
                <div class="col-md-1 mt-4">
                  <custom-switch
                    :listItems="[{text: 'Ativo', value: 1}]"
                    v-model="carousel.ativo"
                    name="carousel.ativo"
                    type="checkbox"
                    stateClassName="p-primary"
                    :error="errors.first('carousel.ativo')">
                  </custom-switch>
                </div>
              </div>

              <hr>
              <div class="row custom-row">
                <div class="col-10">
                  <h5 class="box-title mb-3 mt-2">Slides</h5>
                </div>
                <div class="col-2">
                  <button type="button" class="btn btn-sm btn-outline-light pull-right" @click="onAddSlide">
                    Adicionar
                  </button>
                </div>
              </div>

              <!--  -->
              <div class="row custom-row">
                <div class="col-lg-6" v-for="(item, index) in carousel.hiperlinks" :key="index">
                  <link-card v-if="!item.excluir"
                  :value="item.hiperlink"
                  :blank="true"
                  />
                  <button v-if="!item.excluir" type="button"
                    class="btn btn-link btn-sm mt-1 pull-right" @click="onDeleteSlide(item)">
                    Excluir
                  </button>
                </div>
              </div>

              <hr>

              <!-- Botões -->
              <div class="row custom-row">
                <!-- Submit -->
                <div class="col-12">
                  <button type="button" class="btn btn-sm btn-outline-light pull-right" @click="onSave">
                    Salvar
                  </button>
                  <button type="button" class="btn btn-sm btn-link btn-sm mt-1 pull-right" @click="onCancel">
                    Cancelar
                  </button>
                  <!-- <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onShow">
                    Console
                  </button> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
// Components
// import CustomInput from '@/components/Forms/CustomInput.vue'
import LinkCard from '../../LinkCard.vue'
import CustomSwitch from '@/components/Forms/CustomSwitch.vue'

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Form
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// vue-select
// import vSelect from 'vue-select'
// import 'vue-select/dist/vue-select.css'

// Services
import DashboardService from '@/services/DashboardService'
import HiperlinkSelectModal from '../HiperlinkSelectModal.vue'

export default {
  name: 'CarouselForm',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('dashboard.carousel.new-edit') + ' - %s'
    }
  },
  data () {
    return {
      processEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/carousel',
      isLoading: true,
      isEdit: false,
      formErrors: {},
      fullPage: true,
      submitted: false,
      timer: null,
      delay: 400,
      //  Selected Values (Form)
      carousel: {
      },
      hiperlinkList: [],
      linkClass: '',
      selected: null
    }
  },
  components: {
    Loading,
    LinkCard,
    CustomSwitch
    // vSelect,
    // CustomInput,
  },
  // Apply filters to local filter
  beforeMount () {
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)
  },
  created () {
    let _this = this
    _this.isEdit = _this.$route.name === 'CarouselEdit'
    let id = this.$route.params.id

    // let filters = {
    //   'searchTerm': '',
    //   'per_page': 500
    // }

    if (id) {
      DashboardService.getCarousel(id).then(res => {
        this.carousel = res.data.data
      })
    }

    _this.isLoading = false
  },
  methods: {
    onShow () {
    },
    onAddSlide () {
      let selecteds = []
      let hiperlinks = this.carousel.hiperlinks

      if (this.$util.isset(hiperlinks)) {
        // selecteds = hiperlinks.map(x => {
        //   return x.hiperlink.id_hiperlink
        // })
        selecteds = hiperlinks.reduce((result, x) => {
          if (!this.$util.isset(x.hiperlink.excluir) || !x.hiperlink.excluir) {
            result.push(x.hiperlink.id_hiperlink)
          }
          return result
        }, [])
      }

      this.$modal.show(HiperlinkSelectModal, {
        selecteds: selecteds,
        selected: this.selected
      }, {
        draggable: false,
        adaptive: true,
        scrollable: true,
        clickToClose: true,
        width: '80%',
        height: 'auto'
      },
      {
        'before-close': this.onSelect
      })
    },
    onSelect (data, anotherData) {
    },
    onDeleteSlide (record) {
      this.$set(record, 'excluir', true)
    },
    onCancel (e) {
      var _this = this
      _this.$router.push({ name: 'CarouselList' })
    },
    onSave (e) {
      var _this = this
      this.submitted = true
      _this.$validator.validateAll('frmCarousel').then(valid => {
        if (valid) {
          _this.isLoading = true

          if (this.isEdit) {
          } else {
          }
        }
      })
    },
    onLostFocus () {
      if (this.booking.semana === '') {
        return
      }

      if (this.booking.semana > 52) {
        this.booking.semana = 52
      }

      if (this.booking.semana < 1) {
        this.booking.semana = 1
      }
    },
    filterBy (option, label, search) {
      let splited = search.toLocaleLowerCase().split(' ')
      let lowerLabel = label.toLocaleLowerCase()

      return splited.reduce((founded, term) => {
        founded += lowerLabel.indexOf(term) > -1
        return founded
      })
    }
  },
  computed: {
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }

  .v-select.md-form-control {
    height: 35px;

  }

  td.editable {
    padding: 0;
    vertical-align: middle;
  }

  td.editable input {
    background-color: inherit;
    border: 0;
    width: 100%;
    min-height: 33px;
    box-sizing: border-box;
  }

  .card-subtitle {
    margin-top: 15px;
  }

</style>
